<template>
  <div id="app">
    <div class="content-header px-0">
      <h1 class="title-I">Relatório de Inventário</h1>
    </div>

    <div class="form-inline col-12 p-0">
      <div class="pl-0 col-5 filterInput">
        <b-input-group>
          <b-input-group-prepend is-text
            ><i class="fas fa-search"></i>
          </b-input-group-prepend>
          <input
            v-model="filter"
            type="search"
            class="form-control col-12 w90"
            id="filterInput"
            placeholder="Buscar"
          />
        </b-input-group>
      </div>

      <div class="pl-2 pr-3 col-5 filterInput">
        <select
          id="typeselect"
          class="form-control col-12 w-100"
          v-model="type"
        >
          <option value="0" id="Tags">Tags</option>
          <option value="1" id="GatewayWithoutConfig">Coletores com Configuração</option>
          <option value="2" id="GatewayWithConfig">Coletores sem Configuração</option>
          <option value="3" id="GatewayState">Estado dos coletores</option>
          <option value="4" id="Users" v-if="hasRole(['ADMIN', 'SYSADMIN'])">Usuários</option>
        </select>
      </div>

      <button
        id="inventarioExport"
        v-can="['ADMIN', 'SYSADMIN', 'MANAGER', 'EMPTY', 'DASHBOARD', 'USER']"
        class="btn btn-primary col-2 ml-0 pl-0"
        :disabled="!done"
        @click="statusExportTest"
      >
        <i id="basicb-load-i" v-if="!done" class="fas fa-sync-alt fa-spin"></i>
        Exportar
      </button>
    </div>

    <b-table
      sticky-header="610px"
      responsive="sm"
      :filter="filter"
      striped
      :busy="load || !done"
      empty-text="Nenhum registro encontrado"
      empty-filtered-text="Nenhum relatório encontrado para essa busca"
      borderless
      hover
      :sort-by="type == '0' ? sortBy_tag : sortBy"
      :fields="type == '0' ? fields : type == '1' ? fields_gate_c : type == '2' ? fields_gate : type == '3' ? fields_gate_s : fields_user"
      :items="type == '0' ? inventario : type == '4'? users : gateway"
      class="mt-3 rounded"
      :per-page="perPage"
      :current-page="currentPage"
      @filtered="onFiltered"
    >
      <template v-slot:table-busy>
        <div class="text-center my-2">
          <strong>
            <i class="fas fa-sync-alt fa-spin"></i>
          </strong>
        </div>
      </template>

      <template v-slot:table-colgroup>
        <col
          v-for="field in fields"
          :key="field.key"
          :style="{ width: field.key === 'actions' ? '100px' : 'auto' }"
        />
      </template>

      <!-- eslint-disable-next-line vue/valid-v-slot -->
      <template v-slot:cell(gateway_state.operational_state)="data">
        <span v-if="data.item.gateway_state.operational_state == 'ONLINE'" :title="data.item.gateway_state.operational_state">
          <i class="fas fa-xl fa-check-circle" style="color: green"></i>
        </span>
        <span v-else-if="data.item.gateway_state.operational_state == 'STARTED'" :title="data.item.gateway_state.operational_state">
          <i class="fas fa-xl fa-circle-play" style="color: green"></i>
        </span>
        <span v-else>
          <i class="fas fa-xl fa-times-circle" style="color: red" :title="data.item.gateway_state.operational_state"></i>
        </span>
      </template>

      <!-- eslint-disable-next-line vue/valid-v-slot -->
      <template v-slot:cell(gateway_state.administrative_state)="data">
        <span v-if="data.item.gateway_state.administrative_state == 'OUT-OF-SERVICE'" :title="data.item.gateway_state.administrative_state">
          <i class="fas fa-xl fa-toggle-off" style="color: gray"></i>
        </span>
        <span v-else>
          <i class="fas fa-xl fa-toggle-on" style="color: green" :title="data.item.gateway_state.administrative_state"></i>
        </span>
      </template>

      <!-- eslint-disable-next-line vue/valid-v-slot -->
      <template v-slot:cell(disk_usage)="data">
        <span style="margin-left:5px;">{{data.item.disk_usage}}%</span>
        <span style="float:left" v-if="data.item.disk_usage > 90" :title="'Problema! Uso de Disco ultrapassou 90%'">
          <i class="fas fa-xl fa-exclamation-triangle" style="color: red"></i>
        </span>
        <span style="float:left" v-else-if="data.item.disk_usage > 75" :title="'Alerta! Uso de Disco ultrapassou 75%.'">
          <i class="fas fa-xl fa-exclamation-triangle" style="color: orange"></i>
        </span>
      </template>

      <!-- eslint-disable-next-line vue/valid-v-slot -->
      <template v-slot:cell(memory_usage)="data">
        <span style="margin-left:5px;">{{data.item.memory_usage}}%</span>
        <span style="float:left" v-if="data.item.memory_usage > 90" :title="'Problema! Uso de Memória ultrapassou 90%'">
          <i class="fas fa-xl fa-exclamation-triangle" style="color: red"></i>
        </span>
        <span style="float:left" v-else-if="data.item.memory_usage > 75" :title="'Alerta! Uso de Memória ultrapassou 75%.'">
          <i class="fas fa-xl fa-exclamation-triangle" style="color: orange"></i>
        </span>
      </template>

      <!-- eslint-disable-next-line vue/valid-v-slot -->
      <template v-slot:cell(gateway_state.cpu_usage_1min)="data">
        <span style="margin-left:5px;">{{data.item.gateway_state.cpu_usage_1min}}%</span>
        <span style="float:left" v-if="data.item.gateway_state.cpu_usage_1min > 90" :title="'Problema! Uso de CPU ultrapassou 90%'">
          <i class="fas fa-xl fa-exclamation-triangle" style="color: red"></i>
        </span>
        <span style="float:left" v-else-if="data.item.gateway_state.cpu_usage_1min > 75" :title="'Alerta! Uso de CPU ultrapassou 75%.'">
          <i class="fas fa-xl fa-exclamation-triangle" style="color: orange"></i>
        </span>
      </template>

      <!-- eslint-disable-next-line vue/valid-v-slot -->
      <template v-slot:cell(gateway_state.temp)="data">
        <!-- Celsius Degree -->
        <span style="margin-left:5px;">{{data.item.gateway_state.temp}}°C</span>
        <span style="float:left" v-if="data.item.gateway_state.temp > 70" :title="'Problema! Temperatura ultrapassou 70°C'">
          <i class="fas fa-xl fa-exclamation-triangle" style="color:red;"></i>
        </span>
        <span style="float:left" v-else-if="(data.item.gateway_state.temp > 55)" :title="'Alerta! Temperatura ultrapassou 55°C.'">
          <i class="fas fa-xl fa-exclamation-triangle" style="color:orange;"></i>
        </span>
      </template>

      <template v-slot:cell(type)>{{
        type == 0 ? "Tags" : "Coletores"
      }}</template>

      <template v-slot:cell(info)="data">
        <div v-if="data.item.customer_id" class="info-tags">
          <span v-if="data.item.customer_id">
            <span class="b">Cliente:</span>
            <a :href="`/customer/${data.item.customer_id}/edit`">
              {{ customers[data.item.customer_id] }}
            </a>
          </span>
          <span
            v-can="['SYSADMIN', 'DASHBOARD', 'USER']"
            v-if="data.item.employee"
          >
            <span class="b">Colaborador:</span>
            <a
              :href="`/customer/${data.item.customer_id}/employee/${data.item.employee.id}/edit`"
            >
              {{ data.item.employee.name || "Não alocado" }}
            </a>
          </span>
        </div>
        <div v-else>
          <span class="b">Não alocado</span>
        </div>
      </template>

      <template v-slot:cell(gateway_config)="data">
        <i class="fa fa-xl fa-check" v-if="data.item.gateway_config"></i>
        <i class="fa fa-xl fa-square-xmark" v-else></i>
      </template>
      <template v-slot:cell(roles)="data">{{
        data.item.roles.join(", ")
      }}</template>
    </b-table>
    <div class="row">
      <div v-if="rows" class="col-12 col-md-4 mb-2 pt-1">
        <b-pagination
          v-model="currentPage"
          :total-rows="rows"
          :per-page="perPage"
          align="fill"
          size="sm"
          class="pagination"
        ></b-pagination>
      </div>
      <div class="col-12 col-md-8">
        <div class="btn-group" role="group">
          <b-dropdown
            variant="primary"
            v-model="perPage"
            :text="`Linhas: ${perPage}`"
          >
            <b-dropdown-item
              href="#"
              @click="
                () => {
                  this.perPage = 10;
                }
              "
              >10</b-dropdown-item
            >
            <b-dropdown-item
              href="#"
              @click="
                () => {
                  this.perPage = 20;
                }
              "
              >20</b-dropdown-item
            >
            <b-dropdown-item
              href="#"
              @click="
                () => {
                  this.perPage = 50;
                }
              "
              >50</b-dropdown-item
            >
            <b-dropdown-item
              href="#"
              @click="
                () => {
                  this.perPage = 1000;
                }
              "
              >1000</b-dropdown-item
            >
          </b-dropdown>
        </div>
      </div>
    </div>
    <!--Tabela escondida para criar a exportação em excel por conta da paginação-->
    <b-table
      id="testTable"
      ref="table"
      :filter="filter"
      striped
      :busy="load"
      borderless
      hover
      :fields="type == '0' ? fields : type == '4' ? fields_user : fields_gate"
      :sort-by="type == '0' ? sortBy_tag : sortBy"
      :items="type == '0' ? inventario : type == '4' ? users : gateway"
      class="mt-3 rounded invisible d-none"
      @filtered="onFiltered"
    >
      <template v-slot:table-busy>
        <div class="text-center my-2">
          <strong>
            <i class="fas fa-sync-alt fa-spin"></i>
          </strong>
        </div>
      </template>

      <template v-slot:table-colgroup>
        <col
          v-for="field in fields"
          :key="field.key"
          :style="{ width: field.key === 'actions' ? '100px' : '550px' }"
        />
      </template>

      <template v-slot:cell(type)>{{
        type == 0 ? "Tags" : "Coletores"
      }}</template>

      <template v-slot:cell(info)="data">
        <div v-if="data.item.customer_id" class="info-tags">
          <span v-if="data.item.customer_id"
            ><span class="b">Cliente:</span>
            {{ customers[data.item.customer_id] }}</span
          >
          <span v-if="data.item.employee"
            ><span class="b">Colaborador:</span>
            {{ data.item.employee.name || "Não alocado" }}
          </span>
        </div>
        <div v-else>
          <span class="b">Não alocado</span>
        </div>
      </template>
    </b-table>
    <!-- fim tabela escondidoa -->
  </div>
</template>

<script src="https://cdnjs.cloudflare.com/ajax/libs/vue/2.6.10/vue.js"></script>

<script>
import TwInput from "@/components/TwInput.vue";
import hasRole from "@/utils/hasRole";
const XLSX = require("xlsx");

export default {
  name: "ManagerInventario",
  components: {
    TwInput,
  },
  data() {
    return {
      filter: null,
      perPage: 10,
      localInventario: "",
      currentPage: 1,
      sortBy: "gateway_name",
      sortBy_tag: "tag_label",
      fields: [
        { key: "tag_label", sortable: true, label: "Nome" },
        { key: "mac_address", sortable: true, label: "Identificador" },
        { key: "tag_model", sortable: true, label: "Modelo" },
        { key: "type", sortable: true, label: "Tipo" },
        { key: "tag_type", sortable: true, label: "Subtipo" },
        { key: "info", sortable: true, label: "Informações" },
      ],
      fields_gate: [
        { key: "gateway_name", sortable: true, label: "Nome" },
        { key: "gateway_model", sortable: true, label: "Modelo" },
        { key: "mac_address", sortable: true, label: "Identificador" },
        { key: "serial_number", sortable: true, label: "Serial" },
        { key: "type", sortable: true, label: "Tipo" },
        { key: "gateway_type", sortable: true, label: "Subtipo" },
      ],
      fields_gate_c: [
        { key: "gateway_name", sortable: true, label: "Nome" },
        { key: "gateway_model", sortable: true, label: "Modelo" },
        { key: "mac_address", sortable: true, label: "Identificador" },
        { key: "serial_number", sortable: true, label: "Serial" },
        { key: "type", sortable: true, label: "Tipo" },
        { key: "gateway_type", sortable: true, label: "Subtipo" },
        { key: "gateway_config", label: "Config", sortable: true },
      ],
      fields_gate_s: [
        { key: "gateway_name", sortable: true, label: "Nome" },
        { key: "mac_address", sortable: true, label: "Identificador" },
        { key: "gateway_state.operational_state", label: "Operacional", sortable: true },
        { key: "gateway_state.administrative_state", label: "Administrativo", sortable: true },
        { key: "gateway_state.last_comms", label: "Última comunicação", sortable: true },
        { key: "disk_usage", label: "Disco (%)", sortable: true },
        { key: "memory_usage", label: "RAM (%)", sortable: true },
        { key: "gateway_state.cpu_usage_1min", label: "CPU-1m (%)", sortable: true },
        { key: "gateway_state.temp", label: "Temperatura", sortable: true },

      ],
      fields_user: [
        { key: "name", sortable: true, label: "Nome" },
        { key: "email", sortable: true, label: "Email" },
        { key: "cellphone", sortable: false, label: "Celular" },
        { key: "user_groups.name", sortable: true, label: "Grupo" },
        { key: "roles", sortable: true, label: "Nível de acesso" },
        { key: "status", sortable: true, label: "Situação" },
      ],
      fields_user_export: [
        { key: "name", label: "Nome" },
        { key: "email", label: "Email" },
        { key: "cellphone", label: "Celular" },
        { key: "roles", label: "Nível" },
        { key: "user_groups.name", label: "Grupo" },
        { key: "tfa", label: "Duplo Fator" },
        { key: "status", label: "Status" },
        { key: "workgroups", label: "Workgroups" },
        { key: "type", label: "Tipo" },
        { key: "access_token_expiry.days", label: "Token de Acesso (Dias)" },
        { key: "access_token_expiry.hours", label: "Token de Acesso (Horas)" },
        { key: "access_token_expiry.minutes", label: "Token de Acesso (Minutos)" },
        { key: "access_token_expiry.seconds", label: "Token de Acesso (Segundo)" },
        { key: "refresh_token_expiry.days", label: "Token de Refresh (Dias)" },
        { key: "refresh_token_expiry.hours", label: "Token de Refresh (Horas)" },
        { key: "refresh_token_expiry.minutes", label: "Token de Refresh (Minutos)" },
        { key: "refresh_token_expiry.seconds", label: "Token de Refresh (Segundos)" },
      ],
      type: "0",
      uri: "data:application/vnd.ms-excel;base64,",
      template:
        '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>',
      base64: function (s) {
        return window.btoa(unescape(encodeURIComponent(s)));
      },
      format: function (s, c) {
        return s.replace(/{(\w+)}/g, function (m, p) {
          return c[p];
        });
      },
      type_map: [this.tagExport, this.configExport, this.gatewayExport, this.statusExport, this.userExport]
    };
  },
  mounted() {
    this.$store.dispatch("inventario/get");
    this.$store.dispatch("gateway/get");
    this.$store.dispatch("customer/get");
    this.$store.dispatch("user/get");
  },
  methods: {
    onFiltered() {
      this.currentPage = 1;
    },
    // onListaTagsGateway() {
    //   if (this.type == 0) {
    //     this.$store.dispatch("gateway/get");
    //   } else {
    //     this.$store.dispatch("inventario/get");
    //   }
    // },
    flattenObject(obj) {
      var toReturn = {};

      for (var i in obj) {
        if (!obj.hasOwnProperty(i)) continue;

        if (typeof obj[i] == "object" && obj[i] !== null) {
          var flatObject = this.flattenObject(obj[i]);
          for (var x in flatObject) {
            if (!flatObject.hasOwnProperty(x)) continue;

            toReturn[i + "." + x] = flatObject[x];
          }
        } else {
          toReturn[i] = obj[i];
        }
      }
      return toReturn;
    },
    flat_obj_to_array_of_objs(obj) {
      var arr = [];
      for (var key in obj) {
        // Check if the first character is a number
        if (key.split(".")[0] != null && !isNaN(key.split(".")[0])) {
          if (arr[key.split(".")[0]] == null) {
            arr[key.split(".")[0]] = {};
          }
          let indx = parseInt(key.split(".")[0]);
          let new_key = key.split(".").slice(1).join(".");
          if (obj[key] != null) {
            arr[indx][new_key] = "" + obj[key];
          }
        }
      }
      return arr;
    },
    clearGatewayFields(){
      this.gateway.forEach((element) => {
        delete element.customer_id
        delete element.id
        delete element.gateway_state;
        if (element.gateway_config != null) {
          delete element.gateway_config.updated_at;
          delete element.gateway_config.sender;
          delete element.gateway_config.ble.measured_tx_power_value;
          delete element.gateway_config.ble.measured_tx_power_global_enable;
          delete element.gateway_config.ble.hci_device_num;
        }
        delete element.disk_usage;
        delete element.memory_usage;
      });
    },
    configExport() {
      this.clearGatewayFields();
      let non_nested_objects = this.flattenObject(this.gateway);
      let arr_of_objs = this.flat_obj_to_array_of_objs(non_nested_objects);
      let worksheet = XLSX.utils.json_to_sheet(arr_of_objs, {});
      let new_workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(new_workbook, worksheet, "SheetJS");
      XLSX.writeFile(new_workbook, "GatewayExport.xlsx");
    },
    gatewayExport() {      
      let gtw_obj = [];
      this.gateway.forEach((element) => {
        let obj = {};
        obj.gateway_name = element.gateway_name;
        obj.gateway_model = element.gateway_model;
        obj.mac_address = element.mac_address;
        obj.serial_number = element.serial_number;
        obj.gateway_type = element.gateway_type;
        gtw_obj.push(obj);
      });
      let worksheet = XLSX.utils.json_to_sheet(gtw_obj, {});
      let new_workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(new_workbook, worksheet, "SheetJS");
      XLSX.writeFile(new_workbook, "GatewayExport.xlsx");
    },
    tagExport(){
      let tag_obj = [];
      this.inventario.forEach((element) => {
        let obj = {};
        obj.tag_label = element.tag_label;
        obj.mac_address = element.mac_address;
        obj.tag_model = element.tag_model;
        obj.tag_type = element.tag_type;
        tag_obj.push(obj);
      });
      let worksheet = XLSX.utils.json_to_sheet(tag_obj, {});
      let new_workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(new_workbook, worksheet, "SheetJS");
      XLSX.writeFile(new_workbook, "TagExport.xlsx");
    },
    statusExportTest(){
      this.$store.dispatch("inventario/done");
      this.$store.dispatch("inventario/get");
      this.$store.dispatch("gateway/get");
      this.$store.dispatch("customer/get");
      this.$store.dispatch("user/get");
    },
    statusExport() {
      let gtw_obj = [];
      this.gateway.forEach((element) => {
        let obj = {};
        obj.gateway_name = element.gateway_name;
        obj.mac_address = element.mac_address;
        let state = element.gateway_state;
        obj = {...obj, ...state};
        gtw_obj.push(obj);
      });
      let worksheet = XLSX.utils.json_to_sheet(gtw_obj, {});
      let new_workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(new_workbook, worksheet, "SheetJS");
      XLSX.writeFile(new_workbook, "GatewayStatusExport.xlsx");
    },
    hasRole,
    userExport(){
      function arrayToString(array){
        let string = "";

        for (let i in array){ 
          string = string.concat(array[i]);

          if (i != array.length-1) string = string.concat(",");
        }
        
        return string;
      }

      const usersObj = this.users.map((user) => {
        const newUser = {};
        this.fields_user_export.forEach((field) => {
          const fieldParts = field.key.split(".");
          if (fieldParts.length === 1) {
            newUser[field.key] = user[field.key];
          } else if (fieldParts.length === 2) {
            newUser[field.key] = user[fieldParts[0]][fieldParts[1]];
          }

          if (fieldParts[0] === "workgroups"){
            newUser[field.key] = arrayToString(newUser[field.key]);
          } else if (fieldParts[0] === "roles"){
            newUser[field.key] = newUser[field.key][0]
          } else if (fieldParts[0] === "refresh_token_expiry" || 
                     fieldParts[0] === "access_token_expiry"){
                if(newUser.type === "EXTERNAL"){
                  newUser[field.key] = "";
                } else if (!newUser[field.key]){
                  newUser[field.key] = 0;
                }
          }
        });
        return newUser;
      });

      let non_nested_objects = this.flattenObject(usersObj);
      let arr_of_objs = this.flat_obj_to_array_of_objs(non_nested_objects);
      let worksheet = XLSX.utils.json_to_sheet(arr_of_objs, {});
      /*Increased cell width for better visibility*/
      const wb_cols = Array(32).fill({ wch: 20 });
      worksheet["!cols"] = wb_cols;

      let new_workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(new_workbook, worksheet, "SheetJS");
      let header = [];
      header.push(this.key_to_label_header(usersObj));
      XLSX.utils.sheet_add_aoa(worksheet, header, { origin: "A1" });
      XLSX.writeFile(new_workbook, "UserExport.xlsx");
    },
    key_to_label_header(users) {
      let header = [];
      /*This code is not optimal, but in the worse case it is only a 32 element array, so it doesnt matter*/
      Object.keys(users[0]).forEach((field) => {
        /* Cannot use "break" in forEach*/
        for (let y in this.fields_user_export) {
          if (
            field == this.fields_user_export[y].key &&
            field !== "actions"
          ) {
            header.push(this.fields_user_export[y].label);
            break;
          }
        }
      });
      return header;
    },
  },
  computed: {
    done() {
      return this.$store.getters["inventario/done"];
    },
    load() {
      return this.$store.getters["load"];
    },
    inventario() {
      return this.$store.getters["inventario/inventarios"];
    },
    gateway() {
      let listy = this.$store.getters["gateway/gateways"];
      listy.forEach((element) => {
        if (element.gateway_state != null) {
          element.gateway_state.last_comms = element.gateway_state.last_seen_date + " " + element.gateway_state.last_seen_time;
          if (element.gateway_state.disk_total != 0) {
            element.disk_usage = Math.round((element.gateway_state.disk_used / element.gateway_state.disk_total) * 10000) / 100;
          } else {
            element.disk_usage = 0;
          }
          if (element.gateway_state.mem_total != 0) {
            element.memory_usage = Math.round((element.gateway_state.mem_used / element.gateway_state.mem_total) * 10000) / 100;
          } else {
            element.memory_usage = 0;
          }
        }
      });
      return listy;
    },
    rows() {
      return this.type == "0" ? this.inventario.length : this.type == "4" ? this.users.length : this.gateway.length;
    },
    customers() {
      const customers = this.$store.getters["customer/customers"];
      let ret_customers = {};

      customers.forEach((customer) => {
        ret_customers[`${customer.id}`] = customer.company_name;
      });

      return ret_customers;
    },
    users() {
      return this.$store.getters["user/users"];
    },
  },
  watch: {
    gateway() {
      if (!this.done && this.type == "1" || this.type == "2" || this.type == "3") {
        this.$store.dispatch("inventario/done");
        this.type_map[this.type]();
      }
    },
    inventario() {
      if (!this.done && this.type == "0") {
        this.$store.dispatch("inventario/done");
        this.type_map[this.type]();
      }
    },
    users() {
      if (!this.done && this.type == "4"){
        this.$store.dispatch("inventario/done");
        this.type_map[this.type]();
      }
    },
  },
};
</script>

<style scoped>
.info-tags {
  height: 45px;
  display: flex;
  flex-direction: column;
}
.info-tags {
  max-width: 200px;
  font-size: 12px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.b {
  font-weight: 700;
}
</style>
