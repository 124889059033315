<template>
  <div>
    <Breadcrumb :breadcrumbList="breadcrumbList" />
    <div class="content-header px-0">
      <h1 class="title-I">Importar colaboradores</h1>
    </div>
    <EmployeeImportConfig
      :entireEmployee="editEmployeeConfig"
    ></EmployeeImportConfig>
    <div class="col-12 col-sm-12 p-0 col-md-12">
      <div class="card direct-chat direct-chat-primary">
        <div class="card-body">
          <div class="p-4 bg-white rounded">
            <div class="row">
              <div class="col-12 pr-4">
                <div class="form-group">
                  <UploadExcel
                    :on-success="handleSuccess"
                    :before-upload="beforeUpload"
                    :draggable="true"
                  />
                </div>
              </div>
            </div>

            <div v-show="tableData.length">
              <div class="content-header px-0">
                <h4>Colaboradores encontrados</h4>
              </div>
              <b-table
                id="importEmployeeTable"
                responsive="sm"
                striped
                borderless
                hover
                :fields="fields"
                :items="tableData"
                :busy="!verifyImport"
                class="mt-3 rounded"
                selectable
                @row-selected="onRowSelected"
                select-mode="multi"
                ref="selectableTable"
                editableFields
              >
                <template
                  v-for="field in editableFields"
                  v-slot:[`cell(${field.key})`]="{ item }"
                >
                  <select
                    class="form-control"
                    v-if="field.key === 'gender'"
                    v-model="item[field.key]"
                    :key="field.key"
                    :id="'g-' + field.key"
                  >
                    <option v-for="opt in generos" :key="opt.gender">
                      {{ opt }}
                    </option>
                  </select>

                  <input
                    type="text"
                    class="form-control"
                    v-if="
                      field.key !== 'gender' &&
                      field.key !== 'cpf' &&
                      field.key !== 'cellphone'
                    "
                    @change="validate(tableData)"
                    v-model="item[field.key]"
                    :key="field.key"
                    :id="'imp-' + field.key"
                  />

                  <input
                    type="text"
                    class="form-control"
                    v-mask="'+NNNNNNNNNNNNNNN'"
                    v-if="field.key == 'cellphone'"
                    @change="validate(tableData)"
                    v-model="item[field.key]"
                    :key="field.key"
                    :id="'imp-' + field.key"
                  />

                  <input
                    type="text"
                    class="form-control"
                    v-mask="'NNN.NNN.NNN-NN'"
                    v-if="field.key == 'cpf'"
                    @change="validate(tableData)"
                    v-model="item[field.key]"
                    :key="field.key"
                    :id="'imp-' + field.key"
                  />

                  <span
                    v-if="item.errors"
                    style="color: red"
                    :key="'erro-' + field.key"
                    >{{ item.errors[field.key] }}</span
                  >
                </template>
                <template #cell(selected)="{ rowSelected }">
                  <template v-if="rowSelected">
                    <span aria-hidden="true" class="check-symbol">&check;</span>
                    <span class="sr-only">Selected</span>
                  </template>
                  <template v-else>
                    <span aria-hidden="true">&nbsp;</span>
                    <span class="sr-only">Not selected</span>
                  </template>
                </template>
                <template v-slot:table-busy>
                  <div class="text-center my-2">
                    <strong>
                      <i class="fas fa-sync-alt fa-spin"></i>
                    </strong>
                  </div>
                </template>
                <template v-slot:table-colgroup>
                  <col
                    v-for="field in fields"
                    :key="field.key"
                    :style="{
                      width:
                        field.key === 'selected' ||
                        field.key === 'actions' ||
                        field.key === 'employee_info'
                          ? '150px'
                          : '550px',
                    }"
                  />
                </template>
                <template v-slot:cell(invalid)="data">
                  <span
                    v-if="data.item.invalid"
                    class="valid-import badge badge-danger"
                    >Inválido</span
                  >
                  <span
                    v-if="!data.item.invalid"
                    class="valid-import badge badge-success"
                    >Válido</span
                  >
                </template>
                <template v-slot:cell(employee_info)="data">
                  <div style="text-align: center">
                    <i
                      class="fas fa-xl fa-edit fa-1x"
                      style="color: gray; margin: 2px"
                      @click="editEmployeeConfig = data.item"
                      data-toggle="modal"
                      data-target="#import-config-modal"
                    ></i>
                  </div>
                </template>
                <template v-slot:cell(actions)="data">
                  <a
                    @click="user = data.item"
                    href="#"
                    class="mr-3"
                    data-toggle="modal"
                    data-target="#modalDeleteUser"
                    title="Excluir"
                    v-if="
                      storage
                        ? storage.getItem('email') != data.item.email
                        : false
                    "
                  >
                    <i class="far fa-trash-alt"></i>
                  </a>
                  <router-link
                    :to="{ name: 'user-edit', params: { user: data.item } }"
                    title="Editar"
                  >
                    <i class="fas fa-pen"></i>
                  </router-link>
                </template>
              </b-table>

              <div class="row p-1">
                <button
                  id="selectAll"
                  type="button"
                  class="btn btn-outlined btn-primary mr-2"
                  @click="selectAllRows"
                >
                  Selecionar tudo
                </button>
                <button
                  id="desselectAll"
                  type="button"
                  class="btn btn-outlined btn-primary mr-2"
                  @click="clearSelected"
                >
                  Desselecionar tudo
                </button>
                <button
                  type="button"
                  class="btn btn-outlined btn-danger mr-2"
                  @click="deleteSelected"
                  id="btn-delete-selected"
                >
                  Remover selecionados
                </button>
                <button
                  id="btn-saveEmployees-selected"
                  @click="saveEmployees"
                  class="btn btn-success px-5"
                  :disabled="load"
                >
                  <i v-if="load" class="fas fa-sync-alt fa-spin"></i> Salvar
                </button>
              </div>
            </div>
            <span v-if="!tableData.length" style="color: blue">
              Selecione um arquivo de colaboradores |
              <a v-bind:href="item.loc" download>Modelo de importação xlsx</a>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UploadExcel from "@/components/UploadExcel/index.vue";
import Breadcrumb from "@/components/Breadcrumb";
import EmployeeImportConfig from "@/views/Employee/EmployeeConfig.vue";
import { validCPF } from "../../services/helpers";
import { formatDate, formatDateAPI } from "@/utils/format-fields.js";

export default {
  components: { Breadcrumb, UploadExcel, EmployeeImportConfig },
  data() {
    return {
      breadcrumbList: [],
      generos: ["", "MASCULINO", "FEMININO", "NAO-IDENTIFICADO"],
      tableData: [],
      tableHeader: [],
      storage: null,
      user: "",
      sortBy: "name",
      currentPage: 1,
      fields: [
        { key: "selected", sortable: false, label: "Selecionado" },
        {
          key: "name",
          editable: true,
          sortable: true,
          label: "Nome",
        },
        {
          key: "registration_number",
          editable: true,
          sortable: false,
          label: "Número de Registro",
        },
        { key: "gender", editable: true, sortable: false, label: "Gênero" },
        // {
        //   key: "date_of_birth",
        //   editable: true,
        //   sortable: true,
        //   label: "Data de Nascimento",
        // },
        {
          key: "cpf",
          editable: true,
          sortable: true,
          label: "CPF",
        },
        {
          key: "cellphone",
          editable: true,
          sortable: true,
          label: "Celular",
        },
        {
          key: "email",
          editable: true,
          sortable: true,
          label: "E-mail",
        },
        {
          key: "employee_info",
          label: "Informações",
        },
        {
          key: "invalid",
          label: "Validade",
        },
      ],
      selected: [],
      item: {
        title: "Excel template",
        loc: "/assets/files/model_import_employees.xlsx",
      },
      editEmployeeConfig: {},
    };
  },
  watch: {
    customer() {
      if (this.customer) {
        this.updateList();
      }
    },
    editEmployeeConfig() {
    },
    $route() {
      this.breadcrumbList = this.$route.meta.breadcrumb;
    },
    verifyImport() {
      if (this.verifyImport) this.validate(this.tableData);
    },
  },
  mounted() {
    this.updateList();

    if (this.$route.params.customerId) {
      this.$store.dispatch("customer/getCustomer", {
        customerId: this.$route.params.customerId,
      });
      this.$store.dispatch("employee/getEmployees", {
        customer_id: this.$route.params.customerId,
      });
    } else {
      window.frontMsg("FRNT_MISSING_WARNING", { frontelement: "do Cliente" });
      this.$router.push("/");
    }
  },
  methods: {
    saveEmployees() {
      let dadosSelecionados;

      if (this.selected.length > 0) {
        dadosSelecionados = this.selected;
      } else {
        window.frontMsg("FRNT_MUST_SELECT", { field: "Colaborador" });

        return;
      }
      if (this.validate(dadosSelecionados)) {
        let sendEmployees = [...dadosSelecionados];

        sendEmployees.forEach((element) => {
          if (
            element.cpf !== "" &&
            element.cpf !== null &&
            element.cpf !== undefined
          ) {
            element.cpf = element.cpf.toString();
          } else {
            element.cpf = null;
          }
          if (
            element.cellphone !== "" &&
            element.cellphone !== null &&
            element.cellphone !== undefined
          ) {
            element.cellphone = element.cellphone.toString();
          } else {
            element.cellphone = null;
          }

          element.registration_number = element.registration_number.toString();
          if (element.gender == "" || element.gender == null) {
            element.gender = "NAO-IDENTIFICADO";
          }

          // Change date to ISO format !!!
          if (element.date_of_birth != null) {
            element.date_of_birth = formatDateAPI(element.date_of_birth);
          }
          if (element.functional_data.last_ohc != null) {
            element.functional_data.last_ohc = formatDateAPI(
              element.functional_data.last_ohc
            );
          }
          
          if (element.functional_data.date_of_dismissal){
            element.functional_data.date_of_dismissal = formatDateAPI(element.functional_data.date_of_dismissal)
          }

          if (element.functional_data.date_of_hire){
            element.functional_data.date_of_hire = formatDateAPI(element.functional_data.date_of_hire)
          }
          delete element.invalid;
          delete element.errors;
        });

        this.$store.dispatch("employee/saveListEmployee", {
          customer_id: this.$route.params.customerId,
          data: { employees: sendEmployees },
        });

        this.deleteSelected();
      } else {
        window.frontMsg("FRNT_INVALID_FIELD_WARNING", {
          field: "Colaboradores",
        });

        this.validate(this.tableData);
        return;
      }
    },
    onRowSelected(items) {
      this.selected = items;
    },
    selectAllRows() {
      this.$refs.selectableTable.selectAllRows();
    },
    clearSelected() {
      this.$refs.selectableTable.clearSelected();
    },
    deleteSelected() {
      this.tableData = this.tableData.filter((e) => {
        return !this.selected.includes(e);
      });
      this.$refs.selectableTable.refresh();
    },
    beforeUpload(file) {
      const isLt1M = file.size / 1024 / 1024 < 1;

      if (isLt1M) {
        return true;
      }

      this.$message({
        message: "Please do not upload files larger than 1m in size.",
        type: "warning",
      });
      return false;
    },
    transformDotNotation(data) {
      let transformedData = [];
      data.forEach((element) => {
        let transformedElement = {};
        for (let key in element) {
          let keyArray = key.split(".");
          let currentElement = transformedElement;
          for (let i = 0; i < keyArray.length; i++) {
            if (i === keyArray.length - 1) {
              currentElement[keyArray[i]] = element[key];
            } else {
              if (!currentElement[keyArray[i]]) {
                currentElement[keyArray[i]] = {};
              }
              currentElement = currentElement[keyArray[i]];
            }
          }
        }
        transformedData.push(transformedElement);
      });
      return transformedData;
    },
    handleSuccess({ results, header }) {
      if (results.length < 250) {
        this.tableData = this.transformDotNotation(results);
        let employeeFields = {
          name: "",
          email: "",
          cellphone: "",
          cpf: "",
          registration_number: "",
          gender: "",
          date_of_birth: null,
          anamnesis_data: {
            height: null,
            weight: null,
            smoking: null,
            preexisting_conditions: [],
            physical_activity: null,
            medicines_for_continuous_use: null,
            blood_pressure_level: null,
            pregnancy: null,
          },
          functional_data: {
            area: null,
            department: null,
            position: null,
            work_shift: null,
            work_week: null,
            state: "HIRED",
            date_of_hire: null,
            date_of_dismissal: null,
            supervisor: {
              name: null,
              registration_number: null,
            },
            last_ohc: null,
            health_plan: null,
          },
        };
        // use the ... spread operator to add the fields to each result
        this.tableData = this.tableData.map((result) => {
          //JSON.parse(JSON.stringify()) to make a deep copy
          return { ...JSON.parse(JSON.stringify(employeeFields)), ...result };
        });
        //Fix data that is in wrong format
        this.tableData.forEach((elem, index) => {
          let input = results[index];

          if (input.hasOwnProperty("anamnesis_data.preexisting_conditions")) {
            let unsplit = elem.anamnesis_data.preexisting_conditions;
            elem.anamnesis_data.preexisting_conditions = unsplit.split(", ");
          }
          // Fix date format, from doc format to ISOFormat(date_of_birth and last_ohc) !!!
          if (input.hasOwnProperty("date_of_birth")) {
            let input_date = new Date(input.date_of_birth);
            let date =
              input_date.getFullYear() +
              "-" +
              (input_date.getMonth() + 1).toLocaleString("en-US", {
                minimumIntegerDigits: 2,
                useGrouping: false,
              }) +
              "-" +
              input_date.getDate().toLocaleString("en-US", {
                minimumIntegerDigits: 2,
                useGrouping: false,
              });
            elem.date_of_birth = date;
          }

          if (input.hasOwnProperty("functional_data.last_ohc")) {
            let input_date = new Date(elem.functional_data.last_ohc);
            let date =
              input_date.getFullYear() +
              "-" +
              (input_date.getMonth() + 1).toLocaleString("en-US", {
                minimumIntegerDigits: 2,
                useGrouping: false,
              }) +
              "-" +
              input_date.getDate().toLocaleString("en-US", {
                minimumIntegerDigits: 2,
                useGrouping: false,
              });
            elem.functional_data.last_ohc = date;
          }
        });
        this.tableHeader = header;
        this.validate(this.tableData);
      } else {
        window.frontMsg("FRNT_ACTION_WARNING", {
          reason: "a quantidade de linhas na planilha excede o limite de 50",
        });
      }
    },
    updateList() {
      this.breadcrumbList = this.$route.meta.breadcrumb;
      if (this.customer) {
        this.breadcrumbList[1].label = `Editar cliente ${this.customer.company_name}`;
      }
    },
    createErrorAttribute(employee) {
      employee.errors = {
        name: null,
        registration_number: null,
        cpf: null,
        gender: null,
        cellphone: null,
        email: null,
      };

      employee.invalid = false;
    },
    cpfMaskReconfig(cpf) {
      let cpfMascarado = cpf?.toString().replace(/[.]|-/g, "");
      cpfMascarado = cpfMascarado?.replace(/(.{3})/, "$1.");
      cpfMascarado = cpfMascarado?.replace(/(.{7})/, "$1.");
      cpfMascarado = cpfMascarado?.replace(/(.{11})/, "$1-");

      return cpfMascarado;
    },
    cellphoneMaskReconfig(cellphone) {
      let cellphoneMask;

      if (cellphone?.toString().charAt(0) !== "+")
        cellphoneMask = cellphone?.toString().replace(/(.{0})/, "$1+");
      else cellphoneMask = cellphone;

      return cellphoneMask;
    },
    getFieldLabel(fieldKey) {
      for (let field of this.fields)
        if (field.key === fieldKey) return field.label;
    },
    verifyDoubleData(employee, employeeList, attr, initialIndex) {
      let employeeAttribute = employee[attr]?.toString().trim(); // Backup variable without spaces in start and end of the string.

      // Checks if the data isn't empty.
      if (employeeAttribute) {
        for (let j = parseInt(initialIndex) + 1; j < employeeList.length; j++) {
          //if (employeeList[j][attr]) continue;

          let employeeAttributeCompared = employeeList[j][attr]
            ?.toString()
            .trim(); // Backup variable without spaces in start and end of the string.
          // Checks if has another data with the same input in the table.
          if (
            employeeAttributeCompared &&
            employeeAttribute === employeeAttributeCompared
          ) {
            //employee.invalid = true;
            employeeList[j].invalid = true;

            // Insert the error message in both inputs.
            //employee.errors[attr] = `${this.getFieldLabel(attr)} duplicado nesta importação`;
            employeeList[j].errors[attr] = `${this.getFieldLabel(
              attr
            )} duplicado nesta importação`;
          }
        }
      }
    },
    verifyEmptyInput(employee, attr) {
      employee[attr] = employee[attr]?.toString().trim(); // Backup variable without spaces in start and end of the string.

      if (!employee[attr]) {
        employee.errors[attr] = `${this.getFieldLabel(
          attr
        )} não pode ser vazio`;
        employee.invalid = true;
      }
    },
    verifyRegisteredData(employee, attr) {
      let checkAttribute = (obj) =>
        obj[attr] ? obj[attr] === employee[attr]?.toString() : false;

      if (employee[attr] && this.employees.some(checkAttribute)) {
        employee.invalid = true;
        employee.errors[attr] = `${this.getFieldLabel(attr)} já está em uso`;
      }
    },
    verifyInputLength(employee, attr, minLen, maxLen) {
      if (
        (employee[attr]?.toString() &&
          employee[attr]?.toString().length < minLen) ||
        employee[attr]?.toString().length > maxLen
      ) {
        employee.invalid = true;
        employee.errors[attr] = `${this.getFieldLabel(attr)} inválido`;
      }
    },
    verifyInputCaracters(employee, attr) {
      if (
        employee[attr] &&
        !/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
          employee[attr]?.trim()
        )
      ) {
        employee.invalid = true;
        employee.errors[attr] = "Email Inválido";
      }
    },
    verifyCpfValidation(employee) {
      if (employee.cpf && !validCPF(employee.cpf?.toString())) {
        employee.invalid = true;
        employee.errors["cpf"] = "CPF Inválido";
      }
    },
    isEmployeeValid(employeeList, field, index) {
      let employee = employeeList[index];
      if (field === "name") {
        this.verifyEmptyInput(employee, field);
      } else if (field === "registration_number") {
        this.verifyEmptyInput(employee, field);
        this.verifyDoubleData(employee, employeeList, field, index);
        this.verifyRegisteredData(employee, field);
      } else if (field === "cpf") {
        this.verifyCpfValidation(employee);
        this.verifyDoubleData(employee, employeeList, field, index);
        this.verifyRegisteredData(employee, field);
      } else if (field === "cellphone") {
        this.verifyDoubleData(employee, employeeList, field, index);
        this.verifyInputLength(employee, field, 13, 14);
        this.verifyRegisteredData(employee, field);
      } else if (field === "email") {
        this.verifyDoubleData(employee, employeeList, field, index);
        this.verifyInputCaracters(employee, field);
        this.verifyRegisteredData(employee, field);
      }

      return !employee.invalid;
    },
    validate(employeeList) {
      // This for-loop configures the attributes of errors and the CPF data.
      for (let employee of this.tableData) {
        if (!this.generos.includes(employee.gender)) {
          employee.gender = "";
        }

        this.createErrorAttribute(employee);
        employee.cpf = this.cpfMaskReconfig(employee.cpf);
        employee.cellphone = this.cellphoneMaskReconfig(employee.cellphone);
      }

      for (let field of this.fields) {
        if (field.key != "selected" && field.key != "invalid") {
          // Those fields doesn't need to be verificated.
          for (let index in employeeList) {
            this.isEmployeeValid(employeeList, field.key, index);
          }
        }
      }

      const valido = !this.tableData.some((e) => e.invalid === true);

      this.$refs.selectableTable.refresh();
      return valido;
    },
  },
  computed: {
    load() {
      return this.$store.getters["load"];
    },
    customer() {
      return this.$store.getters["customer/customer"];
    },
    editableFields() {
      return this.fields.filter((field) => field.editable);
    },
    employees() {
      return this.$store.getters["employee/employees"];
    },
    verifyImport() {
      return this.$store.getters["employee/verifyImport"];
    },
  },
};
</script>

<style lang="scss" scoped>
.check-symbol {
  font-size: 3rem;
  margin-top: -30px;
  margin-left: 15px;
  position: absolute;
  display: block;
  text-align: center;
  user-select: none;
}

.valid-import {
  width: 100px;
  font-size: 20px;
  padding: 10px;
  border-radius: 50px;
  transition: none;
}
</style>
