<template>
  <div v-can="['ADMIN', 'SYSADMIN', 'MANAGER', 'FRONTDESK', 'DASHBOARD', 'USER']">
    <div class="row px-0">
      <TwInput label="Busca">
        <b-input-group>
          <b-input-group-prepend is-text
            ><i class="fas fa-search"></i>
          </b-input-group-prepend>
          <input
            v-model="filter"
            type="search"
            class="form-control"
            style="max-width: 900px"
            id="filterInputEmployee"
            placeholder="Buscar"
          />
        </b-input-group>
      </TwInput>

      <div class="col-2 d-flex flex-column-reverse pb-3">
        <b-button
          id="addEmployeebtn"
          style="background-color: #4e01d6"
          v-can="['ADMIN', 'SYSADMIN', 'MANAGER', 'DASHBOARD', 'USER']"
          href="#"
          @click="
            () => {
              this.$router.push({ name: 'customer-employee-add' });
            }
          "
          >Adicionar</b-button
        >
      </div>
      <div class="col-2 d-flex flex-column-reverse pb-3">
        <b-button
          id="deleteAllEmployees"
          style="background-color: #4e01d6"
          v-can="['ADMIN', 'SYSADMIN', 'MANAGER', 'DASHBOARD', 'USER']"
          href="#"
          data-toggle="modal"
          :data-target="hasRole(['ADMIN']) ? '#modalDeleteEmployeesThree' : '#modalDeleteEmployees'"
          >Excluir</b-button
        >
      </div>
      <div class="col-2 d-flex flex-column-reverse pb-3">
        <b-button
          style="background-color: #4e01d6"
          v-can="['ADMIN', 'SYSADMIN', 'MANAGER', 'DASHBOARD', 'USER']"
          href="#"
          @click="
            () => {
              this.$router.push({ name: 'customer-import-employee' });
            }
          "
          >Importar</b-button
        >
      </div>
    </div>
    <div>
      <Modal
        id="modalDeleteEmployee"
        title="Excluir colaborador"
        v-bind:msg="'Tem certeza que deseja excluir o Colaborador?'"
        button="Excluir"
        :method="changeEmployeeState"
      />
      <ModalThree
        id="modalDeleteEmployeeThree"
        title="Excluir colaborador"
        v-bind:msg="'Tem certeza que deseja excluir o Colaborador?'"
        mainButton="Excluir"
        secButton="Deletar"
        buttonMod="danger"
        secButtonMod="orange"
        :secMethod="deleteEmployee"
        :mainMethod="changeEmployeeState"
        :disableMainButton="employeeInactive"
      />
      <Modal
        id="modalDeleteEmployees"
        title="Excluir colaboradores"
        v-bind:msg="'Tem certeza que deseja excluir todos os colaboradores?'"
        button="Excluir"
        :method="changeAllEmployeesState"
      />
      <ModalThree
        id="modalDeleteEmployeesThree"
        title="Excluir colaborador"
        v-bind:msg="'Tem certeza que deseja excluir todos os Colaboradores?'"
        mainButton="Excluir"
        secButton="Deletar"
        buttonMod="danger"
        secButtonMod="orange"
        :secMethod="deleteEmployees"
        :mainMethod="changeAllEmployeesState"
        :disableMainButton="false"
      />
    </div>

    <b-table
      responsive="sm"
      striped
      borderless
      hover
      class="mt-3 rounded"
      no-sort-reset
      ref="selectableTable"
      sticky-header="610px"
      show-empty
      empty-text="Nenhum registro encontrado"
      empty-filtered-text="Nenhum registro encontrado para essa busca"
      head-variant="light"
      :tbody-tr-class="inactiveEmployeeClass"
      :per-page="perPage"
      :current-page="currentPage"
      :sort-by.sync="sortByEmployees"
      :busy="load"
      :filter="filter"
      :fields="fieldsEmployees"
      :items="customerEmployees"
      @filtered="onFiltered"
      @context-changed="changeBsTableState"
    >
      <template v-slot:table-colgroup>
        <col
          v-for="field in fieldsEmployees"
          :key="field.key"
          :style="{
            width: field.key === 'actions' ? '100px' : '550px',
          }"
        />
      </template>
      <template v-slot:table-busy>
        <div class="text-center my-2">
          <strong>
            <i class="fas fa-sync-alt fa-spin"></i>
          </strong>
        </div>
      </template>
      <template v-slot:cell(cellphone)="data">{{
        formatCellphone(data.item.cellphone)
      }}</template>
      <template v-slot:cell(tag)="data">
        <span v-if="data.item.tag">
          <!-- {{ data.item.tag.tag_label }} -->
          Tag: {{ data.item.tag.tag_label }}<br />
          Mac: {{ data.item.tag.mac_address }}<br />
        </span>
      </template>
      <template v-slot:cell(tasg)="data">
        <span v-if="data.item.tag">
          <!-- {{ data.item.tag.tag_label }} -->
          Tag: {{ data.item.tag.tag_label }}<br />
          Mac: {{ data.item.tag.mac_address }}<br />
        </span>
      </template>
      <template v-slot:cell(functional_data.state)="data">
        {{translateState(data.item.functional_data.state)}}
      </template>
      <template v-slot:cell(actions)="data">
        <a
          id="deleteEmployee"
          v-can="['ADMIN', 'SYSADMIN', 'MANAGER', 'DASHBOARD', 'USER']"
          v-bind:style="
            hasRole(['ADMIN']) || data.item.functional_data.state !== 'FIRED' ? 
            'visibility: visible' : 
            'visibility: hidden'
          "
          @click="isEmployeeInactive(data.item)"
          href="#"
          class="mr-3"
          data-toggle="modal"
          :data-target="hasRole(['ADMIN']) ? '#modalDeleteEmployeeThree' : '#modalDeleteEmployee'"
          title="Excluir"
        >
          <i class="far fa-trash-alt iconspace"></i>
        </a>
        <router-link
          class="mr-3"
          id="addtag"
          :to="{
            name: 'customer-employee-add-tags',
            params: {
              customerId: customer ? customer.id : -1,
              employeeId: data.item.id,
            },
          }"
          title="Associar tags"
        >
          <i class="fas fa-tag iconspace"></i>
        </router-link>
        <router-link
          v-can="['ADMIN', 'SYSADMIN', 'MANAGER', 'DASHBOARD', 'USER']"
          :to="{
            name: 'customer-employee-edit',
            params: {
              customerId: customer ? customer.id : -1,
              employeeId: data.item.id,
            },
          }"
          title="Editar"
          id="customer-employee-edit-a"
        >
          <i id="editEmployee" class="fas fa-pen iconspace"></i>
        </router-link>
      </template>
    </b-table>

    <div class="row">
      <div v-if="rows" class="col-12 col-md-4 mb-2 pt-1">
        <b-pagination
          v-model="currentPage"
          :total-rows="rows"
          :per-page="perPage"
          align="fill"
          size="sm"
          class="pagination"
        ></b-pagination>
      </div>
      <div class="col-12 col-md-8">
        <div class="btn-group" role="group">
          <b-dropdown
            variant="primary"
            v-model="perPage"
            :text="`Linhas: ${perPage}`"
          >
            <b-dropdown-item
              href="#"
              @click="
                () => {
                  this.perPage = 10;
                }
              "
              >10</b-dropdown-item
            >
            <b-dropdown-item
              href="#"
              @click="
                () => {
                  this.perPage = 20;
                }
              "
              >20</b-dropdown-item
            >
            <b-dropdown-item
              href="#"
              @click="
                () => {
                  this.perPage = 50;
                }
              "
              >50</b-dropdown-item
            >
            <b-dropdown-item
              href="#"
              @click="
                () => {
                  this.perPage = '1000';
                }
              "
              >1000</b-dropdown-item
            >
          </b-dropdown>
        </div>
      </div>
    </div>

    <div
      v-if="customerEmployees && customerEmployees.length"
      class="col-12 mb-2 pr-4"
    >
      <a
        class="text-danger"
        data-toggle="modal"
        data-target="#modalDeleteEmployees"
        href="#"
      >
        <!-- <i class="fas fa-trash mr-2"></i>
        Excluir todos colaboradores -->
      </a>
    </div>

    <div class="col-12 pr-4 mb-2">
      <router-link
        :to="{
          name: 'customer-employee-add',
          params: { customerId: customer ? customer.id : -1 },
        }"
        id="customer-employee-add-a"
      >
        <!-- <i class="fas fa-plus mr-2"></i>
        Adicionar colaborador -->
      </router-link>
    </div>
    <div class="col-12 pr-4">
      <router-link
        :to="{
          name: 'customer-import-employee',
          params: { customerId: customer ? customer.id : -1 },
        }"
        id="customer-import-employee-a"
      >
        <!-- <i class="fas fa-file-excel mr-2"></i>
        Importar colaboradores -->
      </router-link>
    </div>
  </div>
</template>

<script>
import Modal from "../../../components/Modal.vue";
import ModalThree from "../../../components/ModalThree.vue";
import { formatCellphone } from "../../../utils/format-fields";
import TwInput from "@/components/TwInput.vue";
import { BDropdown, BDropdownItem } from "bootstrap-vue";
import { mapGetters } from "vuex";
import hasRole from "@/utils/hasRole";

export default {
  name: "EmployeeTab",
  data() {
    return {
      filter: null,
      dadoEmployees: null,
      employeeDelete: "",
      perPage: 10,
      currentPage: 1,
      rows: 1,
      sortByEmployees: "name",
      employeeInactive: false,
      filteredEmployees: [],
      fieldsEmployees: [
        { key: "name", sortable: true, label: "Nome" },
        { key: "email", sortable: true, label: "Email" },
        { key: "cellphone", label: "Celular" },
        { key: "tag_label", label: "Tag associada" },
        { key: "mac_address", label: "Mac address" },
        { key: "functional_data.state", label: "Estado" },
        { key: "actions", label: "Ações", class: "actions" },
      ],
    };
  },
  watch: {
    customer() {
      if (this.customer) {
        this.$store.dispatch("employee/getEmployees", {
          customer_id: this.customer.id,
        });
        this.$store.dispatch("tags/getCustomerTags", {
          customer_id: this.customer.id,
        });
      }
    },
    customerEmployees() {
      if (this.$options.name) {
        const ctx = JSON.parse(
          localStorage.getItem(`bstable-state-${this.$options.name}`)
        );
        if (!ctx){
          this.perPage = 10;
          this.currentPage = 1;
          this.sortBy = "";
          this.rows = this.customerEmployees.length;
          return;
        }
        if (ctx.perPage) {
          this.perPage = ctx.perPage;
        }
        if (ctx.sortBy !== "") {
          this.sortBy = ctx.sortBy;
        }
        if (this.perPage !== "" && this.perPage !== null) {
          this.currentPage = ctx.currentPage;
        }
      }
      this.rows = this.customerEmployees.length;
    },
  },
  methods: {
    changeBsTableState(table_state) {
      if (this.$options.name) {
        localStorage.setItem(
          `bstable-state-${this.$options.name}`,
          JSON.stringify(table_state)
        );
      }
    },
    onFiltered(customerEmployees) {
      this.rows = customerEmployees.length;
    },
    formatCellphone(cel) {
      return formatCellphone(cel);
    },
    deleteEmployee() {
      event.preventDefault();
      this.$store.dispatch("employee/deleteEmployee", {
        id: this.employeeDelete.id,
      });
    },
    deleteEmployees() {
      event.preventDefault();

      this.$store.dispatch("employee/deleteEmployees", {
        customer: this.customer,
        employees: this.customerEmployees.map((item) => item.id),
      });
    },
    translateState(employeeState){
      const allStates = [
        {original: "HIRED", translated: "Ativo"},
        {original: "FIRED", translated: "Excluído"},
        {original: "ON-LEAVE", translated: "Afastado"},
        {original: "VACATION", translated: "Em Férias"},
      ]

      for (let state of allStates){
        if (employeeState === state.original) return state.translated
      }

      return employeeState;
    },
    changeEmployeeState(){
      if (this.employeeDelete){
        const originalState = this.employeeDelete.functional_data.state;
        const employee = [{
          date_of_dismissal: null,
          id: this.employeeDelete.id,
          state: "FIRED"
        }];

        this.$store.dispatch("employee/updateEmployeesState", {
          employees: employee,
          customer_id: this.customer.id,
        })
      }
    },
    changeAllEmployeesState(){
      let employees = [];

      for (let employee of this.customerEmployees){
        if (employee.functional_data.state !== "FIRED"){
          employees = [...employees, {
            date_of_dismissal: null,
            id: employee.id,
            state: "FIRED"
          }];
        }
      }

      if (employees.length === 0) {
        window.frontMsg(
          "FRNT_ACTION_WARNING",
          { reason: "todos os Colaboradores já estão excluídos" }
        );
      }

      else {
        this.$store.dispatch("employee/updateEmployeesState", {
          employees: employees,
          customer_id: this.customer.id,
        })
      }
    },
    hasRole,
    inactiveEmployeeClass(item, type){
      if (item && type === "row"){
        if (item.functional_data.state === "FIRED"){
          return "inactive-employee";
        }
      }
    },
    isEmployeeInactive(employee){
      this.employeeDelete = employee;

      if (employee)
        this.employeeInactive = employee.functional_data.state === "FIRED";
      
        
      else
        this.employeeInactive = false;
    }
  },
  computed: {
    ...mapGetters({
      load: "load",
      customerTags: "tags/customerTags",
      allTags: "tags/tags",
    }),
    load() {
      return this.$store.getters["load"];
    },
    customer() {
      return this.$store.getters["customer/customer"];
    },
    employees(){
      return this.$store.getters["employee/employees"];
    },
    customerTags() {
      return this.$store.getters["tags/customerTags"];
    },
    customerEmployees() {
      const tags = this.$store.getters["tags/customerTags"];
      const employees = this.$store.getters["employee/employees"].map(
        (dadoEmployees) => {
          for (var i = 0; i < tags.length; i++) {
            if (tags[i].employee !== null) {
              if (tags[i].employee.id == dadoEmployees.id) {
                dadoEmployees.tag_label = tags[i].tag_label;
                dadoEmployees.mac_address = tags[i].mac_address;

                break;
              }
            }
          }

          return dadoEmployees;
        }
      );
      return employees;
    },
  },
  components: {
    Modal,
    TwInput,
    BDropdown,
    BDropdownItem,
    ModalThree
  },
};
</script>

<style>
.inactive-employee{
  color: #999;
}
@media screen and (max-width: 1040px) {
  .iconspace {
    margin-left: 10px;
    margin-right: 10px;
  }
}
</style>
