<template>
  <div v-can="['ADMIN', 'SYSADMIN', 'MANAGER', 'FRONTDESK']">
    <div class="row px-0">
      <TwInput label="Busca">
        <b-input-group>
          <b-input-group-prepend is-text
            ><i class="fas fa-search"></i>
          </b-input-group-prepend>
          <input
            v-model="filter"
            type="search"
            class="form-control"
            style="max-width: 900px"
            id="filterInputAsset"
            placeholder="Buscar"
            autocomplete="off"
          />
        </b-input-group>
      </TwInput>

      <div class="col-2 d-flex flex-column-reverse pb-3">
        <b-button
          id="addAssetButton"
          style="background-color: #4e01d6"
          v-can="['ADMIN', 'SYSADMIN', 'MANAGER']"
          href="#"
          @click="
            () => {
              this.$router.push({ name: 'customer-asset-add' });
            }
          "
          >Adicionar</b-button
        >
      </div>
      <div class="col-2 d-flex flex-column-reverse pb-3">
        <b-button
          style="background-color: #4e01d6"
          v-can="['ADMIN', 'SYSADMIN', 'MANAGER']"
          id="assetImport"
          @click="
            () => {
              this.$router.push({ name: 'customer-asset-import' });
            }
          "
          >Importar</b-button
        >
      </div>
      <div class="col-2 d-flex flex-column-reverse pb-3">
        <b-button
          style="background-color: #4e01d6"
          v-can="['ADMIN', 'SYSADMIN', 'MANAGER']"
          id="assetExport"
          @click="assetExport"
          >Exportar</b-button
        >
      </div>
    </div>
    <div>
      <Modal
        id="modalDeleteAsset"
        title="Excluir Ativo"
        v-bind:msg="'Tem certeza que deseja excluir ' + assetDelete.name + '?'"
        button="Excluir"
        :method="deleteAsset"
      />
      <Modal
        id="modalDeleteAssets"
        title="Excluir Ativos"
        v-bind:msg="'Tem certeza que deseja excluir todos os ativos?'"
        button="Excluir"
        :method="deleteAssets"
      />
    </div>
    <b-table
      id="assetTable"
      responsive
      striped
      borderless
      hover
      class="mt-3 rounded"
      no-sort-reset
      ref="assetTable"
      show-empty
      empty-text="Nenhum registro encontrado"
      empty-filtered-text="Nenhum registro encontrado para essa busca"
      head-variant="light"
      :per-page="perPage"
      :current-page="currentPage"
      :sort-by.sync="sortByEmployees"
      :busy="load"
      :filter="filter"
      :items="showFields && showFields.length > 0 ? assets : []"
      :fields="showFields"
      @filtered="onFiltered"
      @context-changed="changeBsTableState"
    >
      <template v-slot:table-colgroup>
        <col
          v-for="field in showFields"
          :key="field.key"
          :style="{
            width: field.key === 'actions' ? '100px' : '550px',
          }"
        />
      </template>
      <template v-slot:table-busy>
        <div class="text-center my-2">
          <strong>
            <i class="fas fa-sync-alt fa-spin"></i>
          </strong>
        </div>
      </template>
      <template v-slot:cell(tags)="data">
        <span v-if="data.item.tag">
          {{ data.item.tag.tag_label }}
          Tag: {{ data.item.tag.tag_label }}<br />
          Mac: {{ data.item.tag.mac_address }}<br />
        </span>
      </template>
      <template v-slot:cell(description)="data">
        <span v-if="data.item.description" :title="data.item.description">
          {{ data.item.description.substring(0, 50) + "..." }}
        </span>
      </template>
      <!-- eslint-disable-next-line vue/valid-v-slot -->
      <template v-slot:cell(financial_data.acquisition_cost)="data">
        <span>
          R$
          {{
            (
              Math.round(data.item.financial_data.acquisition_cost * 100) / 100
            ).toFixed(2)
          }}
        </span>
      </template>
      <!-- eslint-disable-next-line vue/valid-v-slot -->
      <template v-slot:cell(financial_data.salvage_value)="data">
        <span>
          R$
          {{
            (
              Math.round(data.item.financial_data.salvage_value * 100) / 100
            ).toFixed(2)
          }}
        </span>
      </template>
      <!-- eslint-disable-next-line vue/valid-v-slot -->
      <template v-slot:cell(financial_data.lifespan)="data">
        <span v-if="data.item.financial_data.lifespan">
          {{ data.item.financial_data.lifespan + " Meses" }}
        </span>
      </template>
      <!-- eslint-disable-next-line vue/valid-v-slot -->
      <template v-slot:cell(financial_data.useful_life)="data">
        <span v-if="data.item.financial_data.useful_life">
          {{ data.item.financial_data.useful_life + " Meses" }}
        </span>
      </template>
      <!-- eslint-disable-next-line vue/valid-v-slot -->
      <template v-slot:cell(functional_data.importance)="data">
        <span v-if="data.item.functional_data.importance === 'ALTA'">
          <i class="fas fa-angle-double-up fa-xl" style="color: red"></i>
        </span>
        <span v-else-if="data.item.functional_data.importance === 'MEDIA'">
          <i class="fas fa-angle-up fa-xl" style="color: orange"></i>
        </span>
        <span v-else-if="data.item.functional_data.importance === 'BAIXA'">
          <i class="fas fa-angle-down fa-xl" style="color: blue"></i>
        </span>
        <span v-if="data.item.functional_data.importance">
          {{ " - " + data.item.functional_data.importance }}</span
        >
      </template>
      <!-- eslint-disable-next-line vue/valid-v-slot -->
      <template v-slot:cell(functional_data.state)="data">
        <span v-if="data.item.functional_data.state === 'ATIVO'">
          <i class="fas fa-check-circle fa-xl" style="color: green"></i>
        </span>
        <span v-else-if="data.item.functional_data.state === 'INATIVO'">
          <i class="fas fa-times-circle fa-xl" style="color: red"></i>
        </span>
        <span v-else-if="data.item.functional_data.state === 'SUCATEADO'">
          <i class="fas fa-box fa-xl" style="color: black"></i>
        </span>
        <span v-if="data.item.functional_data.state">
          {{ " - " + data.item.functional_data.state }}</span
        >
      </template>
      <!-- eslint-disable-next-line vue/valid-v-slot -->
      <template v-slot:cell(financial_data.depreciation_rate)="data">
        <span v-if="data.item.financial_data.depreciation_rate">
          {{ data.item.financial_data.depreciation_rate + "%" }}
        </span>
      </template>
      <!-- eslint-disable-next-line vue/valid-v-slot -->
      <template v-slot:cell(warranty_data.description)="data">
        <span
          v-if="data.item.warranty_data.description"
          :title="data.item.warranty_data.description"
        >
          {{ data.item.warranty_data.description.substring(0, 50) + "..." }}
        </span>
      </template>

      <!-- eslint-disable-next-line vue/valid-v-slot -->
      <template v-slot:cell(financial_data.nf_file)="data">
        <span v-if="data.item.financial_data.nf_file">
          <a :href="data.item.financial_data.nf_file" target="_blank">
            <i class="fas fa-file-pdf fa-xl" style="color: red"></i>
          </a>
        </span>
      </template>

      <template v-slot:cell(actions)="data">
        <a
          id="deleteAsset"
          v-can="['ADMIN', 'SYSADMIN', 'MANAGER']"
          @click="assetDelete = data.item"
          href="#"
          class="mr-3"
          data-toggle="modal"
          data-target="#modalDeleteAsset"
          title="Excluir"
        >
          <i class="far fa-trash-alt iconspace"></i>
        </a>
        <router-link
          class="mr-3 addtagToAsset"
          id="addtag"
          :to="{
            name: 'customer-asset-add-tags',
            params: {
              customerId: customer ? customer.id : -1,
              assetId: data.item.id,
            },
          }"
          title="Associar tags"
        >
          <i class="fas fa-tag iconspace"></i>
        </router-link>
        <router-link
          class="mr-3"
          id="addmaintenance"
          :to="{
            name: 'customer-asset-maintenance-history',
            params: {
              customerId: customer ? customer.id : -1,
              assetId: data.item.id,
            },
          }"
          title="Página de Manutenções"
        >
          <i class="fas fa-screwdriver-wrench iconspace"></i>
        </router-link>
        <router-link
          v-can="['ADMIN', 'SYSADMIN', 'MANAGER']"
          :to="{
            name: 'customer-asset-edit',
            params: {
              customerId: customer ? customer.id : -1,
              assetId: data.item.id,
            },
          }"
          title="Editar"
          id="customer-asset-edit-a"
        >
          <i id="assetEdit" class="fas fa-pen iconspace"></i>
        </router-link>
      </template>
    </b-table>

    <div class="row">
      <div v-if="rows" class="col-12 col-md-4 mb-2 pt-1">
        <b-pagination
          v-model="currentPage"
          :total-rows="rows"
          :per-page="perPage"
          align="fill"
          size="sm"
          class="pagination"
        ></b-pagination>
      </div>
      <div class="col-12 col-md-8">
        <div class="btn-group" role="group">
          <b-dropdown
            variant="primary"
            v-model="perPage"
            :text="`Linhas: ${perPage}`"
          >
            <b-dropdown-item
              href="#"
              @click="
                () => {
                  this.perPage = 10;
                }
              "
              >10</b-dropdown-item
            >
            <b-dropdown-item
              href="#"
              @click="
                () => {
                  this.perPage = 20;
                }
              "
              >20</b-dropdown-item
            >
            <b-dropdown-item
              href="#"
              @click="
                () => {
                  this.perPage = 50;
                }
              "
              >50</b-dropdown-item
            >
            <b-dropdown-item
              href="#"
              @click="
                () => {
                  this.perPage = '1000';
                }
              "
              >1000</b-dropdown-item
            >
          </b-dropdown>
          <b-dropdown
            variant="primary"
            style="margin-left: 10px"
            v-model="perPage"
            :text="`Filtro de Colunas`"
          >
            <div class="asset-dropdown-columns">
              <div v-for="(field, index) in fieldsAssets" :key="field.key">
                <b-form-checkbox
                  v-if="index === 0"
                  v-model="selectAll"
                  @change="selectAllFields ? selectAllFields : null"
                  id="select-all"
                  >Selecione Todos
                </b-form-checkbox>
                <b-row v-if="index % 5 === 0" class="mb-2">
                  <b-col
                    v-for="field in fieldsAssets.slice(index, index + 5)"
                    :key="field.key"
                  >
                    <b-form-checkbox
                      v-if="field.editable === false ? field.editable : true"
                      v-model="field.visible"
                      :id="field.key"
                      :name="field.key"
                      :key="field.key"
                      :checked="field.visible"
                      @change="changeBsTableState"
                    >
                      {{ field.label }}
                    </b-form-checkbox>
                  </b-col>
                </b-row>
              </div>
            </div>
          </b-dropdown>
        </div>
      </div>
    </div>

    <div v-if="assets && assets.length" class="col-12 mb-2 pr-4">
      <a
        class="text-danger"
        data-toggle="modal"
        data-target="#modalDeleteEmployees"
        href="#"
      >
        <!-- <i class="fas fa-trash mr-2"></i>
        Excluir todos colaboradores -->
      </a>
    </div>

    <div class="col-12 pr-4 mb-2">
      <router-link
        :to="{
          name: 'customer-employee-add',
          params: { customerId: customer ? customer.id : -1 },
        }"
        id="customer-employee-add-a"
      >
        <!-- <i class="fas fa-plus mr-2"></i>
        Adicionar colaborador -->
      </router-link>
    </div>
    <div class="col-12 pr-4">
      <router-link
        :to="{
          name: 'customer-import-employee',
          params: { customerId: customer ? customer.id : -1 },
        }"
        id="customer-import-employee-a"
      >
        <!-- <i class="fas fa-file-excel mr-2"></i>
        Importar colaboradores -->
      </router-link>
    </div>
  </div>
</template>

<script>
import Modal from "../../../components/Modal.vue";
import { formatCellphone } from "../../../utils/format-fields";
import TwInput from "@/components/TwInput.vue";
import { BDropdown, BDropdownItem } from "bootstrap-vue";
import { mapGetters } from "vuex";
import { formatDate, formatDateAPI } from "@/utils/format-fields.js";
const XLSX = require("xlsx");

export default {
  name: "AssetTab",
  data() {
    return {
      selectAll: true,
      filter: null,
      dadoEmployees: null,
      assetDelete: "",
      perPage: 10,
      currentPage: 1,
      rows: 1,
      sortByEmployees: "name",
      filteredEmployees: [],
      fieldsAssets: [
        {
          key: "name",
          sortable: true,
          visible: true,
          label: "Nome do Ativo",
          empty: "",
        },
        {
          key: "internal_id",
          sortable: true,
          visible: true,
          label: "Identificador Interno",
          empty: null,
        },
        {
          key: "serial_number",
          sortable: true,
          visible: true,
          label: "Número de Série",
          empty: null,
        },
        {
          key: "functional_data.area",
          sortable: true,
          visible: true,
          label: "Área",
          empty: null,
        },
        {
          key: "functional_data.department",
          sortable: true,
          visible: true,
          label: "Departamento",
          empty: null,
        },
        {
          key: "functional_data.supervisor",
          sortable: true,
          visible: true,
          label: "Responsável",
          empty: "",
        },
        {
          key: "functional_data.importance",
          sortable: true,
          visible: true,
          label: "Importância",
          empty: null,
        },
        {
          key: "financial_data.acquisition_cost",
          sortable: true,
          visible: true,
          label: "Custo de Aquisição",
          empty: null,
        },
        {
          key: "financial_data.salvage_value",
          sortable: true,
          visible: true,
          label: "Valor Residual",
          empty: null,
        },
        {
          key: "functional_data.state",
          sortable: true,
          visible: true,
          label: "Estado Funcional",
          empty: null,
        },
        {
          key: "warranty_data.status",
          sortable: true,
          visible: true,
          label: "Estado da Garantia",
          empty: null,
        },
        {
          key: "warranty_data.valid_until",
          sortable: true,
          visible: true,
          label: "Validade da Garantia",
          empty: null,
        },
        {
          key: "category",
          sortable: true,
          visible: true,
          label: "Categoria",
          empty: "",
        },
        {
          key: "subcategory",
          sortable: true,
          visible: true,
          label: "Subcategoria",
          empty: "",
        },
        {
          key: "acquisition_date",
          sortable: true,
          visible: true,
          label: "Data de Aquisição",
          empty: null,
        },
        {
          key: "financial_data.depreciation_rate",
          sortable: true,
          visible: true,
          label: "Taxa de Depreciação Anual",
          empty: null,
        },
        {
          key: "financial_data.expiration_date",
          sortable: true,
          visible: true,
          label: "Data de Descarte",
          empty: null,
        },
        {
          key: "financial_data.lifespan",
          sortable: true,
          visible: true,
          label: "Vida Útil Total",
          empty: null,
        },
        {
          key: "financial_data.ncm",
          sortable: true,
          visible: true,
          label: "NCM",
          empty: null,
        },
        {
          key: "financial_data.useful_life",
          sortable: true,
          visible: true,
          label: "Vida Útil Restante",
          empty: null,
        },
        {
          key: "financial_data.borrower",
          sortable: true,
          visible: true,
          label: "Comodatário",
          empty: null,
        },
        {
          key: "financial_data.last_nf_number",
          sortable: true,
          visible: true,
          label: "Número da Última NF",
          empty: null,
        },
        {
          key: "financial_data.asset_movement_date",
          sortable: true,
          visible: true,
          label: "Data do Movimento do Ativo",
          empty: null,
        },
        {
          key: "financial_data.nf_file",
          sortable: true,
          visible: true,
          label: "Arquivo da NF",
          empty: "",
        },
        {
          key: "functional_data.manufacturer",
          sortable: true,
          visible: true,
          label: "Fabricante",
          empty: null,
        },
        {
          key: "functional_data.model",
          sortable: true,
          visible: true,
          label: "Modelo",
          empty: null,
        },
        {
          key: "functional_data.quality_state",
          sortable: true,
          visible: true,
          label: "Estado de Qualidade",
          empty: null,
        },
        {
          key: "functional_data.training_required",
          sortable: true,
          visible: false,
          label: "Treinamento Requerido",
          empty: null,
        },
        {
          key: "warranty_data.code",
          sortable: true,
          visible: false,
          label: "Código da Garantia",
          empty: "",
        },
        {
          key: "warranty_data.description",
          sortable: true,
          visible: false,
          label: "Descrição da Garantia",
          empty: "",
        },
        {
          key: "description",
          sortable: true,
          visible: false,
          label: "Descrição",
          empty: "",
        },
        {
          key: "actions",
          label: "Ações",
          visible: true,
          editable: true,
          stickyColumn: true,
        },
      ],
    };
  },
  watch: {
    customer() {
      if (this.customer) {
        this.$store.dispatch("employee/getEmployees", {
          customer_id: this.customer.id,
        });
        this.$store.dispatch("tags/getCustomerTags", {
          customer_id: this.customer.id,
        });

        this.$store.dispatch("asset/getAssets", {
          customer_id: this.customer.id,
        });
      }
    },
    assets() {
      if (this.$options.name) {
        const ctx = JSON.parse(
          localStorage.getItem(`bstable-state-${this.$options.name}`)
        );
        if (!ctx) {
          this.perPage = 10;
          this.currentPage = 1;
          this.sortBy = "";
          this.rows = this.assets.length;
          return;
        }
        if (ctx.perPage) {
          this.perPage = ctx.perPage;
        }
        if (ctx.sortBy !== "") {
          this.sortBy = ctx.sortBy;
        }
        if (this.perPage !== "" && this.perPage !== null) {
          this.currentPage = ctx.currentPage;
        }
      }
      this.rows = this.assets.length;
    },
  },
  mounted() {
    this.$store.dispatch("asset/getAssets", {
      customer_id: this.customer.id,
    });
  },
  methods: {
    changeBsTableState(table_state) {
      if (this.$options.name) {
        localStorage.setItem(
          `bstable-state-${this.$options.name}`,
          JSON.stringify(table_state)
        );
      }
    },
    onFiltered(assets) {
      this.rows = assets.length;
    },
    formatCellphone(cel) {
      return formatCellphone(cel);
    },
    deleteAsset() {
      event.preventDefault();
      this.$store.dispatch("asset/deleteAsset", {
        id: this.assetDelete.id,
        customer_id: this.customer.id,
      });
    },
    deleteAssets() {
      event.preventDefault();

      this.$store.dispatch("asset/deleteAsset", {
        customer: this.customer,
        employees: this.assets.map((item) => item.asset_id),
      });
    },
    replaceStrings(obj) {
      for (let key in obj) {
        if (typeof obj[key] === "object") {
          this.replaceStrings(obj[key]);
        }

        if (!obj[key]) {
          for (let field of this.fieldsAssets) {
            if (key === field.key) {
              obj[key] = field.empty === null ? "NULL" : "";
              break;
            }
          }
        }

        if (
          typeof obj[key] === "string" &&
          obj[key].length === 10 &&
          obj[key].split("/").length === 3
        ) {
          obj[key] = formatDate(obj[key]);
        }
      }
      return obj;
    },
    assetExport() {
      const assets = this.assets.map((asset) => {
        const newAsset = {};
        this.showFields.forEach((field) => {
          const fieldParts = field.key.split(".");
          if (fieldParts.length === 1) {
            newAsset[field.key] = asset[field.key];
          } else if (fieldParts.length === 2) {
            newAsset[field.key] = asset[fieldParts[0]][fieldParts[1]];
          } else if (fieldParts.length === 3) {
            newAsset[field.key] =
              asset[fieldParts[0]][fieldParts[1]][fieldParts[2]];
          }
        });
        
        delete newAsset.actions;
        delete newAsset["financial_data.nf_file"];
        delete newAsset["financial_data.last_nf_number"];

        return newAsset;
      });

      for (let asset of assets) {
        this.replaceStrings(asset);
      }

      let non_nested_objects = this.flattenObject(assets);
      let arr_of_objs = this.flat_obj_to_array_of_objs(non_nested_objects);

      let worksheet = XLSX.utils.json_to_sheet(arr_of_objs, {});
      /*Increased cell width for better visibility*/
      const wb_cols = Array(32).fill({ wch: 20 });
      worksheet["!cols"] = wb_cols;

      let new_workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(new_workbook, worksheet, "SheetJS");
      let header = [];
      header.push(this.key_to_label_header(assets));
      XLSX.utils.sheet_add_aoa(worksheet, header, { origin: "A1" });
      XLSX.writeFile(new_workbook, "AssetExport.xlsx");
    },
    key_to_label_header(assets) {
      let header = [];
      /*This code is not optimal, but in the worse case it is only a 32 element array, so it doesnt matter*/
      Object.keys(assets[0]).forEach((field) => {
        /* Cannot use "break" in forEach*/
        for (let y in this.fieldsAssets) {
          if (
            field == this.fieldsAssets[y].key &&
            field !== "actions" &&
            field !== "financial_data.nf_file" &&
            field !== "financial_data.last_nf_number"
          ) {
            header.push(this.fieldsAssets[y].label);
            break;
          }
        }
      });
      return header;
    },
    flattenObject(obj) {
      let toReturn = {};

      for (let i in obj) {
        if (!obj.hasOwnProperty(i)) continue;

        if (typeof obj[i] == "object" && obj[i] !== null) {
          let flatObject = this.flattenObject(obj[i]);
          for (let x in flatObject) {
            if (!flatObject.hasOwnProperty(x)) continue;

            toReturn[i + "." + x] = flatObject[x];
          }
        } else {
          toReturn[i] = obj[i];
        }
      }
      return toReturn;
    },
    flat_obj_to_array_of_objs(obj) {
      let arr = [];
      for (let key in obj) {
        // Check if the first character is a number
        if (key.split(".")[0] != null && !isNaN(key.split(".")[0])) {
          if (arr[key.split(".")[0]] == null) {
            arr[key.split(".")[0]] = {};
          }
          let indx = parseInt(key.split(".")[0]);
          let new_key = key.split(".").slice(1).join(".");
          if (obj[key] != null) {
            arr[indx][new_key] = "" + obj[key];
          }
        }
      }
      return arr;
    },
  },
  computed: {
    ...mapGetters({
      load: "load",
      customerTags: "tags/customerTags",
      allTags: "tags/tags",
    }),
    load() {
      return this.$store.getters["load"];
    },
    customer() {
      return this.$store.getters["customer/customer"];
    },
    customerTags() {
      return this.$store.getters["tags/customerTags"];
    },
    assets() {
      return this.$store.getters["asset/assets"];
    },
    flattenAssets() {
      return this.flat_obj_to_array_of_objs(this.flattenObject(this.assets));
    },
    /* computed for show fields fieldsAssets if visible */
    showFields() {
      return this.fieldsAssets.filter((item) => item.visible);
    },
    selectAllFields() {
      this.fieldsAssets.forEach((field) => {
        field.visible = this.selectAll;
      });
    },
  },
  components: {
    Modal,
    TwInput,
    BDropdown,
    BDropdownItem,
  },
};
</script>

<style>
@media screen and (max-width: 1040px) {
  .iconspace {
    margin-left: 10px;
    margin-right: 10px;
  }
}
#assetTable th div {
  width: 200px;
}
.asset-dropdown-columns {
  display: flex !important;
  width: 100%;
  padding: 0.25rem 1rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
.custom-control {
  font-size: 10pt;
  width: 200px;
}
.b-table-sticky-column:last-child {
  right: 0;
  text-align: center;
}
</style>
